@import "../../variables";

.SearchBar {
  border: 1px solid $color-border;
  border-radius: 3px;
}

.SearchResultContainer {
  height: fit-content;
  color: $color-headline;
  background-color: $color-component;
  border-left: 1px solid $color-border;
  border-right: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 100%;
  max-height: 200px;
  overflow-x: clip;
  overflow-y: auto;


  .SearchResultItem {
    width: 100%;
    border-bottom: 1px solid $color-border;
    cursor: pointer;
    padding: 5px 0 5px 5px;

    &:last-child {
      border: none;
    }
  }
}

