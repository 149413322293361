@import "../../variables";

// inspired by https://codepen.io/Shven/pen/JjJMwo
* {
  margin: 0;
  padding: 0;
}

#wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
  display: none;

  @media only screen and (max-device-width: 480px) {
    display: flex;
  }
}

label {
  cursor: pointer;

  &:focus {
    outline: none
  }
}

.Menu {
  padding: 10px 10px 10px 10px;
  position: absolute;
  background: $color-component;
  width: 220px;
  height: 100%;
  transform: translate3d(-240px, 0, 0);
  transition: transform 0.35s;
  filter: drop-shadow(1px 1px 3px color-mix(in srgb, $color-background 75%, #000));
  pointer-events: all;

  .MenuTitle {
    color: $color-headline;
    padding-bottom: 5px;
  }

  label.MenuToggle {
    position: absolute;
    right: -30px;
    top: 90px;
    width: 30px;
    height: 30px;
    line-height: 0;
    display: flex;
    padding: 3px 3px 3px 0;
    text-indent: -9999px;
    background: $color-component 50% 50% / 25px 25px no-repeat;
    border-bottom-right-radius: 25%;
    border-top-right-radius: 25%;
    justify-content: center;
    pointer-events: all;
    align-items: center;

    svg {
      padding-left: 3px;
      display: flex;
      width: 75%;
      height: 75%;
      color: $color-headline;
    }
  }

  ul {
    list-style-type: none;

    li {
      padding-bottom: 5px;

      a {
        color: $color-headline;
        text-decoration: none;
        font-size: 20px;
      }
    }
  }
}

.MenuCheckbox {
  display: none;
}

.MenuCheckbox:checked + .Menu {
  transform: translate3d(0, 0, 0)
}

html, body {
  height: 100%;
}

p {
  margin-bottom: 15px;
}




