@import "../../../variables";

Input, textarea {
  border: none;
  background-color: $color-component;
  color: $color-headline;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.MealCreationForm {
  color: $color-headline;
  border: 2px solid $color-border;
  border-radius: 5px;
  background-color: $color-component;
  margin: 20px 5% 85px 5%;
  padding: 5px;

  .NameInputWrapper {
    padding-bottom: 10px;
    margin-top: 10px;
    border-bottom: solid 2px $color-border;

    .NameInput {
      text-align: center;
      font-size: $font-size-large;
      text-decoration: underline $color-headline;
    }
  }


  .IngredientList {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid $color-border;
    font-size: $font-size-medium;
    display: block;

    .IngredientItemWrapper {
      padding: 5px 5px 5px 20px;
      border-bottom: solid 1px $color-border;
      display: flex;
      justify-content: space-between;

      .IngredientItem {
      }

      .IngredientItemDeleteButton {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $color-border;
        border-color: $color-border;
        font-size: 14px;
        height: 2em;
        width: 2em;
        cursor: pointer;
        color: $color-delete;
      }
    }
  }

  .AddNewIngredientBar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    .AddNewIngredientButton {
      margin-top: 5px;
      border-radius: 50%;
      background-color: $color-border;
      border-color: $color-border;
      color: $color-headline;
      height: 2em;
      width: 2em;
      font-size: 26px;
      cursor: pointer;
    }
  }

  .InstructionWrapper {
    margin-top: 10px;
    border-bottom: 2px solid $color-border;
    font-size: $font-size-medium;

    .InstructionInput {
      resize: none;
      width: 90%;
      padding: 0 0 5px 20px;
    }
  }

  .PreferencesWrapper {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .PreferencesSelect {
      cursor: pointer;
      background-color: unset;
      border: none;
      color: $color-headline;
      font-weight: bold;
    }
  }

}

.SavingButton {
  margin: 5px;
  border-radius: 5px;
  background-color: $color-border;
  border-color: $color-border;
  color: $color-headline;
  height: 30px;
  font-size: $font-size-large;
  min-width: 210px;
  min-height: 60px;
  position: fixed;
  bottom: 5px;
  right: 5px;
}
