@import "../../../variables";

.OverviewContainer {
  color: $color-headline;
  border: 2px solid $color-border;
  border-radius: 5px;
  background-color: $color-component;
  margin: 20px 5% 85px 5%;
  padding: 5px;
  display: flex;
  flex-direction: column;

  a {
    color: $color-headline;
    text-decoration: none;
    font-size: 18px;
  }


  :not(:last-child) {
    border-bottom: 1px solid $color-border;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
}