$color-border: #09938d;
$color-background: #4aaca2;
$color-component: #47bd97;
$color-headline: #b7ffda;
$color-headline-hover: #e2efec;
$color-veggie: #016401;
$color-no-veggie: #e81d1d;
$color-black: #000000;
$color-delete: #931616;


$font-size-normal: 12px;
$font-size-medium: 16px;
$font-size-large: 26px; 
