@import "../../../variables";

.Reroll {
  margin: 5px;
  border-radius: 5px;
  background-color: $color-border;
  border-color: $color-border;
  color: $color-headline;
  height: 30px;
  filter: drop-shadow(5px 5px 5px color-mix(in srgb, $color-background 75%, #000));
}

.Reroll {
  font-size: $font-size-large;
  min-width: 210px;
  min-height: 60px;
  position: fixed;
  bottom: 5px;
  right: 5px;
}